import { Regex } from "./messages";

export const getLogoUrl = () => {
  return `${process.env.PARTNER_MEDIA_CDN}/img/esyoil/logo.svg`;
};

export const getMkLogin = () => {
  return `${process.env.MAIN_ESYOIL}/mein-konto/`;
};

export const getMkLogout = () => {
  return `${process.env.MAIN_ESYOIL}/mein-konto/logout`;
};

export const getPkLogin = () => {
  return `https://partner.${process.env.MAIN_ESYOIL.replace(
    "https://www.",
    "",
  )}/`;
};

export const getKeyByValue = (object: Record<string, unknown>, value: any) => {
  for (const key in object) {
    if (object.hasOwnProperty(key) && object[key] === value) {
      return key;
    }
  }
  return null;
};

export const isValidPhoneNumber = (input: string): boolean => {
  // Entferne alle Leerzeichen aus dem Eingabestring
  const stringWithoutSpaces = input.replace(/\s/g, "");

  // Überprüfe, ob der String mindestens sechs Ziffern enthält
  return Regex.phone.test(stringWithoutSpaces);
};

export const decodeUrlForPlace = (
  countyName: string,
  priceType?: "pelletspreise" | "heizoelpreise",
) => {
  const type = priceType || "heizoelpreise";
  return `/${type}/${decodePlaceName(countyName)}`;
};

export const decodePlaceName = (countyName: string) => {
  return translateGermanUmlaute(
    decodeURIComponent(countyName.replaceAll("/", "|").toLowerCase()),
  );
};

export const translateGermanUmlaute = (text: string): string => {
  return text
    .replaceAll(/ä/g, "ae")
    .replaceAll(/ü/g, "ue")
    .replaceAll(/ö/g, "oe")
    .replaceAll(/Ä/g, "Ae")
    .replaceAll(/Ü/g, "Ue")
    .replaceAll(/Ö/g, "Oe");
};
