import { decodePlaceName, decodeUrlForPlace } from "~/operations/shared";
import { BreadCrumbDetail } from "~/types/BreadCrumb";

import dayjs from "dayjs";
import "dayjs/locale/de";
import isoWeek from "dayjs/plugin/isoWeek";
import { translateStateName } from "./mixins";
dayjs.extend(isoWeek);
dayjs.locale("de");

const CITY_STATES = ["bremen", "hamburg", "berlin"];

export const BASE_PATHS = {
  heizoel: "heizoelpreise",
  pellets: "pelletspreise",
} as const;

export type BasePathType = keyof typeof BASE_PATHS;

const addBaseBreadcrumb = function (
  breadcrumbs: BreadCrumbDetail[],
  basePath: BasePathType,
): BreadCrumbDetail[] {
  return [
    {
      name: "",
      linkUrl: `/${basePath}`,
      linkValue: `/${basePath}`,
    },
    ...breadcrumbs,
  ];
};

const createStateBreadcrumb = function (
  state: string,
  basePath: BasePathType,
): BreadCrumbDetail {
  const encodedState = decodePlaceName(encodeURIComponent(state.toLowerCase()));
  return {
    name: state,
    linkUrl: `/${basePath}/bundeslaender/${encodedState}`,
    linkValue: `/${basePath}/bundeslaender/${encodedState}`,
  };
};

const createCityStateBreadcrumb = function (
  state: string,
  basePath: BasePathType,
): BreadCrumbDetail {
  const stateUrlSegment = decodePlaceName(
    encodeURIComponent(state.toLowerCase()),
  );
  return {
    name: `Kreisfreie Stadt ${state}`,
    linkUrl: `/${basePath}/bundeslaender/${stateUrlSegment}/stadt-${stateUrlSegment}`,
    linkValue: `/${basePath}/bundeslaender/${stateUrlSegment}/stadt-${stateUrlSegment}`,
    lastItem: false,
  };
};

const createKreisfreieStadtBreadcrumb = function (
  state: string,
  city: string,
  basePath: BasePathType,
): BreadCrumbDetail {
  const encodedState = decodePlaceName(encodeURIComponent(state.toLowerCase()));
  const encodedCity = decodePlaceName(encodeURIComponent(city.toLowerCase()));
  return {
    name: `Kreisfreie Stadt ${city}`,
    linkUrl: `/${basePath}/bundeslaender/${encodedState}/stadt-${encodedCity}`,
    linkValue: `/${basePath}/bundeslaender/${encodedState}/stadt-${encodedCity}`,
  };
};

const createCountyBreadcrumb = function (
  state: string,
  county: string,
  basePath: BasePathType,
): BreadCrumbDetail {
  const encodedState = decodePlaceName(encodeURIComponent(state.toLowerCase()));
  const encodedCounty = decodePlaceName(
    encodeURIComponent(county.toLowerCase()),
  );
  return {
    name: county,
    linkUrl: `/${basePath}/bundeslaender/${encodedState}/${encodedCounty}`,
    linkValue: `/${basePath}/bundeslaender/${encodedState}/${encodedCounty}`,
  };
};

const createCityBreadcrumb = function (
  city: string,
  zipcode: string,
  basePath: BasePathType,
): BreadCrumbDetail {
  return {
    name: city,
    zipcode,
    linkUrl: decodeUrlForPlace(
      `${basePath}-${city}-${zipcode}`,
      basePath as "pelletspreise" | "heizoelpreise",
    ),
    linkValue: decodeUrlForPlace(
      `${basePath}-${city}-${zipcode}`,
      basePath as "pelletspreise" | "heizoelpreise",
    ),
    lastItem: true,
  };
};

const formatBreadcrumbName = function (
  item: BreadCrumbDetail,
  index: number,
  totalLength: number,
  basePath: BasePathType,
) {
  const baseText = basePath === "heizoel" ? "Heizölpreise" : "Pelletspreise";

  if (index === 0) return baseText;

  let name = `${baseText} ${item.name}`;

  if (
    (totalLength > 3 && index === totalLength - 2) ||
    (totalLength === 3 && index === totalLength - 1 && !item.zipcode)
  ) {
    name = `${baseText} Landkreis ${item.name}`;
  }

  if (item.zipcode) {
    name += ` ${item.zipcode}`;
  }

  return name;
};

const createBreadCrumbsStructuredData = function (
  breadCrumb: BreadCrumbDetail[],
  basePath: BasePathType = "heizoel",
) {
  return [
    {
      "@type": "ListItem",
      position: 1,
      item: {
        "@id": "https://www.esyoil.com",
        name: "Startseite",
      },
    },
    ...breadCrumb.map((item, index) => {
      const isLastItem = index === breadCrumb.length - 1;
      const isDistrictCity = item.linkValue?.includes("/stadt-");
      let name = formatBreadcrumbName(item, index, breadCrumb.length, basePath);

      if (isLastItem && isDistrictCity) {
        name = name.replace(" Landkreis", "");
      }

      return {
        "@type": "ListItem",
        position: index + 2,
        item: {
          "@id": `https://www.esyoil.com${item.linkValue}`,
          name: name,
        },
      };
    }),
  ];
};

const getBasePathType = (urlPath: string): BasePathType => {
  return urlPath.includes("pelletspreise") ? "pellets" : "heizoel";
};

const createBaseUrl = (basePath: BasePathType): string => {
  return `/${BASE_PATHS[basePath]}`;
};

const createStatePath = (basePath: BasePathType): string => {
  return `${createBaseUrl(basePath)}/bundeslaender`;
};

const createStateCountyPath = (
  basePath: BasePathType,
  state: string,
  county?: string,
): string => {
  let path = createStatePath(basePath);
  path += `/${state}`;
  if (county) {
    path += `/${county}`;
  }
  return path;
};

const formatBreadCrumbByCountyAndBundesland = (
  fullPath: string,
  router: any,
): BreadCrumbDetail[] => {
  const params = fullPath.startsWith("/")
    ? fullPath.substring(1).split("/")
    : fullPath.split("/");

  const basePath = getBasePathType(fullPath);
  const crumbs = __createBreadCrumbsForCountyAndBundesland(
    params,
    router,
    basePath,
  );

  return crumbs;
};

const __createBreadCrumbsForCountyAndBundesland = (
  params: any,
  router: any,
  basePath: keyof typeof BASE_PATHS,
): BreadCrumbDetail[] => {
  const crumbs: BreadCrumbDetail[] = [];

  params.reduce((path, param) => {
    const currentPath = `${path}/${param}`;
    const match = router.match(currentPath);
    const routeParams = match.params;

    if (Object.keys(routeParams).length > 0) {
      const routeParamKeys = Object.keys(routeParams);
      let stateCountyPath = `/${BASE_PATHS[basePath]}/bundeslaender`;
      let isStateCity = false;
      let currentState = "";

      routeParamKeys.forEach((paramName) => {
        const paramValue = routeParams[paramName];
        if (paramName === "id" && paramValue === "bundeslaender") {
          return;
        }
        if (paramName === "category") {
          currentState = paramValue.toLowerCase();
          isStateCity = CITY_STATES.includes(currentState);
        }
      });

      routeParamKeys.forEach((paramName) => {
        const paramValue = routeParams[paramName];
        const isStateOrCounty = paramName === "category" || paramName === "id";

        // Append the parameter value to the state/county path if it's a category or id
        if (isStateOrCounty) {
          if (paramName === "id" && paramValue.startsWith("stadt-")) {
            stateCountyPath += `/${paramValue}`;
          } else {
            stateCountyPath += `/${paramValue}`;
          }
        }

        if (
          !crumbs.some(
            (crumb) => crumb.name === translateStateName(paramValue),
          ) &&
          !(isStateCity && paramName === "category") &&
          paramValue !== "bundeslaender"
        ) {
          const isDistrictCity =
            paramName === "id" && paramValue.startsWith("stadt-");
          const crumb = __createCrumb(
            paramValue,
            stateCountyPath,
            isDistrictCity,
            basePath,
          );
          crumbs.push(crumb);
        }
      });
    }
    return currentPath;
  }, "");
  return crumbs;
};

const addPrefixBreadcrumb = (
  breadcrumbs: BreadCrumbDetail[],
  basePath: BasePathType,
): BreadCrumbDetail[] => {
  const baseUrl = createBaseUrl(basePath);
  return [
    {
      name: "",
      linkUrl: baseUrl,
      linkValue: baseUrl,
    },
    ...breadcrumbs,
  ];
};

const __createCrumb = (
  paramValue: string,
  stateCountyBaseUrl: string,
  isDistrictCity = false,
  basePath: BasePathType,
): BreadCrumbDetail => {
  let name = translateStateName(paramValue);

  if (isDistrictCity) {
    const cityName = paramValue.startsWith("stadt-")
      ? translateStateName(paramValue.replace("stadt-", ""))
      : translateStateName(paramValue);
    name = `Kreisfreie Stadt ${cityName}`;

    if (CITY_STATES.includes(cityName.toLowerCase())) {
      stateCountyBaseUrl = createStateCountyPath(
        basePath,
        cityName.toLowerCase(),
        `stadt-${cityName.toLowerCase()}`,
      );
    }
  }

  return {
    name,
    linkUrl: `${stateCountyBaseUrl}`,
    linkValue: `${stateCountyBaseUrl}`,
  };
};

export {
  addBaseBreadcrumb,
  addPrefixBreadcrumb,
  createBreadCrumbsStructuredData,
  createCityBreadcrumb,
  createCityStateBreadcrumb,
  createCountyBreadcrumb,
  createKreisfreieStadtBreadcrumb,
  createStateBreadcrumb,
  formatBreadCrumbByCountyAndBundesland,
  getBasePathType,
};
